<template>
    <div class="home d-flex" v-resize="onResize">
        <v-card class="d-flex flex-grow-1">
            <v-container fluid class="d-flex flex-grow-1 flex-column">
                <v-row class="d-flex flex-grow-1">
                    <v-col cols="12">
                        <div style="display: flex">
                            <v-tabs v-model="selectedModule">
                                <v-tab key="lpAccount">LP Account</v-tab>
                                <v-tab key="positionRec">Position Rec</v-tab>
                                <v-tab key="mapping">Mapping</v-tab>
                            </v-tabs>
                            <!-- <v-btn
                                style="float: right"
                                color="primary"
                                small
                                class="mt-2"
                                @click="toLPConfig"
                                v-show="showButton"
                                >To LP Config</v-btn
                            > -->
                        </div>
                    </v-col>
                </v-row>

                <LPInfo
                    v-if="selectedModule === 0"
                    :tableHeight="lpInfoTableHeight"
                    @addSymbol="handleAddSymbol"
                />
                <PositionRec
                    v-if="selectedModule === 1"
                    :tableHeight="positionRecTableHeight"
                />
                <SymbolMap
                    v-if="selectedModule === 2"
                    :tableHeight="mapTableHeight"
                    :batch-maps="waitToAddSymbols"
                />
            </v-container>
        </v-card>
    </div>
</template>
<script>
import LPInfo from "./component/ComponentLPInfo";
import PositionRec from "./component/ComponentPositionRec";
import SymbolMap from "./component/ComponentSymbolMapping";
import { mapState, mapActions } from "vuex";
import introJs from "intro.js";
import { TutorialConstant } from "@assets/tutorialConstant";
import { putAuthUser } from "@services/account/auth";

export default {
    components: { LPInfo, PositionRec, SymbolMap },
    data() {
        return {
            tutorialConstant: TutorialConstant,
            selectedModule: 0,
            mapTableHeight: 500,
            positionRecTableHeight: 500,
            lpInfoTableHeight: 500,
            waitToAddSymbols: [],
            showButton: false,
        };
    },
    created() {
        this.setHeights();
    },
    methods: {
        ...mapActions("LP", [
            "getLPInfoAction",
            "updateLpTutorialAction",
            "updateLPModuleAction",
        ]),
        getTutorial() {
            if (this.selectedModule == 0) {
                return introJs().setOptions({
                    steps: [
                        {
                            element: document.getElementById("positionTable"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "positionTable"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "positionTable"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById("lpChange"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "lpChange"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "lpChange"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById("lpRefresh"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "lpRefresh"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "lpRefresh"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById("intervalChange"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "intervalChange"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "intervalChange"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById("accountTable"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "accountTable"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "accountTable"
                            ]["intro"],
                        },
                        {
                            element:
                                document.getElementById("accountFunctions"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "accountFunctions"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "accountFunctions"
                            ]["intro"],
                        },
                    ],
                });
            }
            if (this.selectedModule == 1) {
                return introJs().setOptions({
                    steps: [
                        {
                            element:
                                document.getElementById("positionRecTable"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "positionRecTable"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "positionRecTable"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById(
                                "positionRecLastCheck"
                            ),
                            title: this.tutorialConstant["lpPageIntro"][
                                "positionRecLastCheck"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "positionRecLastCheck"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById(
                                "symbolWithBreakTable"
                            ),
                            title: this.tutorialConstant["lpPageIntro"][
                                "symbolWithBreakTable"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "symbolWithBreakTable"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById(
                                "symbolWithoutBreakTable"
                            ),
                            title: this.tutorialConstant["lpPageIntro"][
                                "symbolWithoutBreakTable"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "symbolWithoutBreakTable"
                            ]["intro"],
                        },
                        {
                            element:
                                document.getElementById("positionRecRefresh"),
                            title: this.tutorialConstant["lpPageIntro"][
                                "positionRecRefresh"
                            ]["title"],
                            intro: this.tutorialConstant["lpPageIntro"][
                                "positionRecRefresh"
                            ]["intro"],
                        },
                    ],
                });
            }
        },
        setHeights() {
            const innerHeight = window.innerHeight;
            this.lpInfoTableHeight =
                innerHeight - 243 > 500 ? innerHeight - 243 : 500;
            this.mapTableHeight =
                innerHeight - 283 > 500 ? innerHeight - 283 : 500;
            this.positionRecTableHeight =
                innerHeight - 283 > 500 ? innerHeight - 283 : 500;
        },
        onResize() {
            this.setHeights();
        },
        /**
         * Change tab view
         * @param   {[type]}  key  [key description]
         * @return  {[type]}       [return description]
         */
        changeView(key) {
            this.selectedModule = key;
        },
        handleAddSymbol(symbols) {
            this.waitToAddSymbols = symbols;
            this.selectedModule = 2;
        },
        toLPConfig() {
            let routeData = this.$router.resolve({
                name: "Settings",
                query: { editing_target: "lp" },
            });
            window.open(routeData.href, "_blank");
        },
    },
    computed: {
        ...mapState("LP", ["lpTutorial"]),
    },
    watch: {
        lpTutorial(nv) {
            if (!!nv) {
                this.getTutorial()
                    .oncomplete(() => this.updateLpTutorialAction(false))
                    .onexit(() => this.updateLpTutorialAction(false))
                    .start();
            }
        },
        selectedModule(nv) {
            this.updateLPModuleAction(nv);
        },
    },
    mounted() {
        // handle batch add map
        if (this.$route.query.symbol_map) {
            this.selectedModule = 2;
            this.waitToAddSymbols = decodeURIComponent(
                this.$route.query.symbol_map
            ).split(",");
            this.$router.push({ query: {} }); // clean route params
        }
        let params = { active: 1 };
        this.getLPInfoAction(params);
        const permission = JSON.parse(
            localStorage.getItem("permission")
        ).frontPermission;

        if (permission?.settings?.child.includes("lpConfig")) {
            this.showButton = true;
        } else {
            this.showButton = false;
        }
        setTimeout(() => {
            if (
                !localStorage
                    .getItem("tutorial_presented")
                    .includes("lp_page")
            ) {
                this.getTutorial()
                    .oncomplete(function () {
                        const params = { pages: "" };
                        if (!localStorage.getItem("tutorial_presented")) {
                            params.pages = "lp_page";
                        } else {
                            params.pages =
                                localStorage.getItem("tutorial_presented") +
                                ",lp_page";
                        }
                        putAuthUser(params).then((res) => {
                            if (res.status === 200) {
                                localStorage.setItem(
                                    "tutorial_presented",
                                    params.pages
                                );
                            }
                        });
                    })
                    .onexit(function () {
                        const params = { pages: "" };
                        if (!localStorage.getItem("tutorial_presented")) {
                            params.pages = "lp_page";
                        } else {
                            params.pages =
                                localStorage.getItem("tutorial_presented") +
                                ",lp_page";
                        }
                        putAuthUser(params).then((res) => {
                            if (res.status === 200) {
                                localStorage.setItem(
                                    "tutorial_presented",
                                    params.pages
                                );
                            }
                        });
                    })
                    .start();
            }
        }, 500);
    },
};
</script>
