<template>
    <div class="home d-flex">
        <v-container fluid class="d-flex flex-grow-1">
            <v-row dense class="d-flex flex-grow-1">
                <v-col lg="7" md="7" xs="12" cols="12">
                    <v-card
                        outlined
                        class="d-flex flex-column"
                        style="height: 100%"
                        id="positionTable"
                    >
                        <v-card-title
                            style="border-bottom: 1px solid #404040"
                            class="pt-2 pb-2"
                        >
                            <div class="text-left v-card-custom-title-left">
                                Current Position
                            </div>
                            <div class="text-right v-card-custom-title-right">
                                <v-menu offset-y dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="mr-2 ml-2"
                                            small
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            id="lpChange"
                                        >
                                            {{ selectedLp }}
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item
                                            v-for="(item, index) in lpList"
                                            :key="item.id"
                                            v-on:click="changeLp(item)"
                                        >
                                            <v-list-item-title>{{
                                                item.lp_name.toUpperCase()
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <div style="display: inline-table">
                                    <v-btn
                                        small
                                        color="default"
                                        :loading="positionLoading"
                                        @click="refreshPosition"
                                        id="lpRefresh"
                                        ><v-icon>mdi-refresh</v-icon></v-btn
                                    >
                                    <v-menu offset-y dense>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                small
                                                color="default"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                id="intervalChange"
                                            >
                                                {{
                                                    positionTimer == 0
                                                        ? "off"
                                                        : positionTimer + "s"
                                                }}
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                v-for="item in timerOption"
                                                :key="item.value"
                                                v-on:click="
                                                    changeTimer(
                                                        item.value,
                                                        'position'
                                                    )
                                                "
                                            >
                                                <v-list-item-title>{{
                                                    item.name
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-progress-linear
                                        v-show="positionTimer != 0"
                                        style="
                                            position: relative;
                                            height: 3px;
                                            bottom: 3px;
                                        "
                                        :value="positionTimerValue"
                                    ></v-progress-linear>
                                </div>

                                <v-btn
                                    class="mr-2 ml-2"
                                    v-if="selectedSymbols.length"
                                    small
                                    color="default"
                                    @click="handleAddSymbol"
                                    >Add Symbol</v-btn
                                >
                            </div>
                        </v-card-title>
                        <v-data-table
                            v-model="selectedSymbols"
                            class="flex-grow-1"
                            dense
                            :headers="positionHeader"
                            :items="lpPositionData"
                            item-key="id"
                            :loading="positionLoading"
                            loading-text="Loading... Please wait"
                            :items-per-page="-1"
                            :height="tableHeight"
                            :hide-default-footer="true"
                            fixed-header
                            :header-props="{ sortIcon: null }"
                            :show-select="selectedLp != 'ALL'"
                        >
                            <template v-slot:header.Symbol="{ header }">
                                {{ header.text }}
                                <v-menu
                                    offset-y
                                    :close-on-content-click="false"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click.prevent="
                                                onSearch('symbolSearch')
                                            "
                                        >
                                            mdi-magnify
                                        </v-icon>
                                    </template>
                                    <div
                                        style="
                                            background-color: #1e1e1e;
                                            width: 280px;
                                        "
                                    >
                                        <v-text-field
                                            v-model="search"
                                            class="px-4"
                                            type="text"
                                            ref="symbolSearch"
                                            label="Enter the search term"
                                            style="width: 100%"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        <v-btn
                                            @click="search = ''"
                                            small
                                            text
                                            color="primary"
                                            class="ml-2 mb-2"
                                            style="padding: 6px"
                                        >
                                            Clean
                                        </v-btn>
                                    </div>
                                </v-menu>
                            </template>

                            <template v-slot:item="{ item }">
                                <tr>
                                    <td
                                        column="select"
                                        v-if="selectedLp != 'ALL'"
                                    >
                                        <v-checkbox
                                            dense
                                            v-model="selectedSymbols"
                                            :disabled="
                                                item.isSelectable != true
                                            "
                                            class="mt-0 pt-0"
                                            :value="item"
                                            hide-details
                                        ></v-checkbox>
                                    </td>
                                    <td column="lp" class="fixCol_first">
                                        {{ item.LP.toUpperCase() }}
                                    </td>
                                    <td column="Symbol">
                                        <span
                                            v-if="item.isSelectable != true"
                                            >{{ item.Symbol }}</span
                                        >

                                        <v-tooltip bottom v-else>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <a
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    style="color: red"
                                                    @click="
                                                        selectedSymbols.push(
                                                            item
                                                        );
                                                        handleAddSymbol();
                                                    "
                                                >
                                                    {{ item.Symbol }}
                                                </a>
                                            </template>
                                            <span
                                                >Action required to map this
                                                symbol
                                            </span>
                                        </v-tooltip>
                                    </td>
                                    <td column="Margin_Rate" align="right">
                                        {{
                                            item["Margin Rate"] === "N/A"
                                                ? item["Margin Rate"]
                                                : (item["Margin Rate"] * 100).toFixed(0) +
                                                  "%"
                                        }}
                                    </td>
                                    <td column="Position" align="right">
                                        {{
                                            item.Position === "N/A"
                                                ? item.Position
                                                : numberWithCommas(
                                                      parseFloat(
                                                          item.Position
                                                      ).toFixed(2)
                                                  )
                                        }}
                                        <!-- {{item.Position}} -->
                                    </td>
                                    <td column="Total_VWAP" align="right">
                                        {{
                                            item["Total VWAP"] === "N/A"
                                                ? item["Total VWAP"]
                                                : decimalPlaceCorrection(
                                                      item.symbol,
                                                      item["Total VWAP"]
                                                  )
                                        }}
                                    </td>
                                    <td column="Margin" align="right">
                                        {{
                                            item.Margin === "N/A"
                                                ? item.Margin
                                                : numberWithCommas(
                                                      parseFloat(
                                                          item.Margin
                                                      ).toFixed(2)
                                                  )
                                        }}
                                    </td>
                                    <td
                                        column="Unrealized_PNL_CCY_Account"
                                        align="right"
                                    >
                                        {{
                                            item[
                                                "Margin Unrealized P&L in Account CCY"
                                            ] === "N/A"
                                                ? item[
                                                      "Margin Unrealized P&L in Account CCY"
                                                  ]
                                                : numberWithCommas(
                                                      parseFloat(
                                                          item[
                                                              "Margin Unrealized P&L in Account CCY"
                                                          ]
                                                      ).toFixed(2)
                                                  )
                                        }}
                                    </td>
                                    <td
                                        column="Unrealized_PNL_CCY_Quote"
                                        align="right"
                                    >
                                        {{
                                            item[
                                                "Unrealized P&L in Quote CCY"
                                            ] === "N/A"
                                                ? item[
                                                      "Unrealized P&L in Quote CCY"
                                                  ]
                                                : numberWithCommas(
                                                      parseFloat(
                                                          item[
                                                              "Unrealized P&L in Quote CCY"
                                                          ]
                                                      ).toFixed(2)
                                                  )
                                        }}
                                    </td>
                                    <td
                                        column="last_update"
                                        class="fixCol_last"
                                    >
                                        {{ item.updated_at }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col lg="5" md="5" xs="12" cols="12">
                    <v-card
                        outlined
                        class="d-flex flex-column"
                        style="height: 100%"
                        id="accountTable"
                    >
                        <v-card-title
                            style="border-bottom: 1px solid #404040"
                            class="pt-2 pb-2"
                        >
                            <div class="text-left v-card-custom-title-left">
                                Account Info
                            </div>
                            <div class="text-right v-card-custom-title-right">
                                <div
                                    style="display: inline-table"
                                    id="accountFunctions"
                                >
                                    <v-btn
                                        small
                                        color="default"
                                        :loading="accountLoading"
                                        @click="refreshAccount"
                                        ><v-icon>mdi-refresh</v-icon></v-btn
                                    >
                                    <v-menu offset-y dense>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                small
                                                color="default"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                {{
                                                    accountTimer == 0
                                                        ? "off"
                                                        : accountTimer + "s"
                                                }}
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                v-for="item in timerOption"
                                                :key="item.value"
                                                v-on:click="
                                                    changeTimer(
                                                        item.value,
                                                        'account'
                                                    )
                                                "
                                            >
                                                <v-list-item-title>{{
                                                    item.name
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-progress-linear
                                        v-show="accountTimer != 0"
                                        style="
                                            position: relative;
                                            height: 3px;
                                            bottom: 3px;
                                        "
                                        :value="accountTimerValue"
                                    ></v-progress-linear>
                                </div>
                            </div>
                        </v-card-title>

                        <v-data-table
                            class="flex-grow-1"
                            dense
                            :headers="accountHeader"
                            :items="filteredAccountData"
                            :loading="accountLoading"
                            loading-text="Loading... Please wait"
                            :items-per-page="-1"
                            :height="tableHeight"
                            :hide-default-footer="true"
                            fixed-header
                            :header-props="{ sortIcon: null }"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td column="lp" class="fixCol_first">
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    style="
                                                        color: red;
                                                        margin-right: 5px;
                                                    "
                                                    size="15"
                                                    v-if="isWorkingDay"
                                                    v-show="
                                                        isOutsideOfTimeOffset(
                                                            item.updated_at
                                                        )
                                                    "
                                                >
                                                    mdi-alert
                                                </v-icon>
                                            </template>
                                            <span
                                                >LP account info not up to date,
                                                last update at:
                                                {{ item.updated_at }}</span
                                            >
                                        </v-tooltip>
                                        {{ item.LP.toUpperCase() }}
                                    </td>
                                    <td column="Margin%" align="right">
                                        <div
                                            :style="
                                                'color:' +
                                                (item['Margin Utilization %'] >
                                                50
                                                    ? 'red'
                                                    : 'white')
                                            "
                                        >
                                            {{
                                                item[
                                                    "Margin Utilization %"
                                                ].toFixed(2) + "%"
                                            }}
                                        </div>
                                    </td>
                                    <td column="Used" align="right">
                                        {{
                                            numberWithCommas(
                                                parseFloat(item.Margin).toFixed(
                                                    2
                                                )
                                            )
                                        }}
                                    </td>
                                    <td column="Free" align="right">
                                        {{
                                            numberWithCommas(
                                                item["Free Margin"].toFixed(2)
                                            )
                                        }}
                                    </td>
                                    <td column="Equity" align="right">
                                        {{
                                            numberWithCommas(
                                                item.Equity.toFixed(2)
                                            )
                                        }}
                                    </td>
                                    <td column="FPNL" align="right">
                                        {{
                                            numberWithCommas(
                                                item["Unrealized P&L"].toFixed(
                                                    2
                                                )
                                            )
                                        }}
                                    </td>
                                    <td column="Balance" align="right">
                                        {{
                                            numberWithCommas(
                                                item.Balance.toFixed(2)
                                            )
                                        }}
                                    </td>
                                    <td column="Credit" align="right">
                                        {{
                                            item.Credit == null
                                                ? null
                                                : numberWithCommas(
                                                      parseFloat(
                                                          item.Credit
                                                      ).toFixed(2)
                                                  )
                                        }}
                                    </td>
                                    <td
                                        class="fixCol_last"
                                        column="Last Update"
                                    >
                                        {{ item.updated_at }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import common from "@assets/js/common";

export default {
    mixins: [permission, helper, snackbar],
    props: {
        tableHeight: Number,
    },
    data() {
        return {
            search: "",
            selectedLp: "ALL",
            selectedLpId: -1,
            loadTable: false,
            timeOffset: 1000 * 60,
            selectedSymbols: [],
            positionTimer: 0,
            accountTimer: 0,
            timerOption: [
                { name: "off", value: 0 },
                { name: "15s", value: 15 },
                { name: "30s", value: 30 },
            ],
            positionTimeInterval: null,
            accountTimeInterval: null,
            positionTimerValue: 0,
            accountTimerValue: 0,
            hardRefresh: null,
        };
    },
    watch: {
        /**
         * Watch on lplist change
         * @param   {[type]}  nv  [nv description]
         * @return  {[type]}      [return description]
         */
        lpList(nv) {
            // assign the initial value to selected value
            if (nv.length !== 0 && this.selectedLp === "ALL") {
                this.selectedLp = nv[0].lp_name;
                this.selectedLpId = nv[0].id;
                this.getLPAccountAction();
                this.getLPPositionAction();
            }
        },
        /**
         * Change selected lp value
         * @param   {[type]}  nv  [nv description]
         * @return  {[type]}      [return description]
         */
        selectedLpId(nv) {
            this.UPDATE_BATCH_ADD_LP_ID(nv);
        },
        positionTimer(nv) {
            if (nv == 0) {
                clearInterval(this.positionTimeInterval);
            } else {
                clearInterval(this.positionTimeInterval);
                this.positionTimerValue = 0;
                this.positionTimeInterval = setInterval(() => {
                    if (this.positionTimerValue >= 100) {
                        this.positionTimerValue -= 100;
                        this.getLPPositionAction();
                    } else {
                        this.positionTimerValue += 10 / this.positionTimer;
                    }
                }, 100);
            }
        },
        accountTimer(nv) {
            if (nv == 0) {
                clearInterval(this.accountTimeInterval);
            } else {
                clearInterval(this.accountTimeInterval);
                this.accountTimerValue = 0;
                this.accountTimeInterval = setInterval(() => {
                    if (this.accountTimerValue >= 100) {
                        this.accountTimerValue -= 100;
                        this.getLPAccountAction();
                    } else {
                        this.accountTimerValue += 10 / this.accountTimer;
                    }
                }, 100);
            }
        },
    },
    computed: {
        ...mapState("LP", [
            "accountHeader",
            "accountData",
            "positionHeader",
            "positionData",
            "lpList",
            "accountLoading",
            "positionLoading",
        ]),
        ...mapState("Home", ["symbolDP"]),
        isWorkingDay() {
            return common.isWeekday();
        },
        activeLP() {
            let result = [];
            this.lpList.map((item) => {
                if (item.active === 1) {
                    result.push(item.lp_name.toUpperCase());
                }
            });

            return result;
        },
        filteredAccountData() {
            let result = [];
            this.accountData.map((item) => {
                if (
                    this.activeLP.includes(item.LP.split("_")[0].toUpperCase())
                ) {
                    result.push(item);
                }
            });
            return result;
        },
        /**
         * Return selected lp position data
         * @return  {[type]}  [return description]
         */
        lpPositionData() {
            if (this.selectedLp === "ALL") {
                return this.positionData
                    .filter(
                        (item) =>
                            this.activeLP.includes(item.LP.toUpperCase()) &&
                            item.Symbol.toUpperCase().match(
                                this.search.toUpperCase()
                            )
                    )
                    .map((item) => {
                        item.isSelectable = item.Symbol.split("_").length > 1;
                        return item;
                    });
            } else {
                return this.positionData
                    .filter(
                        (item) =>
                            item.LP === this.selectedLp &&
                            item.Symbol.toUpperCase().match(
                                this.search.toUpperCase()
                            )
                    )
                    .map((item) => {
                        item.isSelectable = item.Symbol.split("_").length > 1;
                        return item;
                    });
            }
        },
    },
    methods: {
        ...mapActions("LP", ["getLPAccountAction", "getLPPositionAction"]),
        ...mapMutations("LP", ["UPDATE_BATCH_ADD_LP_ID"]),
        ...mapActions("Home", ["getSymbolDPAction"]),
        /**
         * Refresh Account Data
         * @return  {[type]}  [return description]
         */
        refreshAccount() {
            this.getLPAccountAction();
        },
        refreshPosition() {
            this.getLPPositionAction();
        },
        /**
         * Change lp
         * @return  {[type]}  [return description]
         */
        changeLp(data) {
            this.selectedLp = data.lp_name;
            this.selectedLpId = data.id;
        },
        /**
         * Check lp last update time is out of date or not
         * Threshold seconds is 60s
         * @param   {[type]}  time  [time description]
         * @return  {[type]}        [return description]
         */
        isOutsideOfTimeOffset(time) {
            if (
                Math.abs(
                    new Date(common.getMT4TimeString()).getTime() -
                        new Date(time).getTime()
                ) < this.timeOffset
            )
                return false;
            return true;
        },
        /**
         * batch add symbol
         */
        handleAddSymbol() {
            this.$emit(
                "addSymbol",
                this.selectedSymbols.map((item) => item.Symbol)
            );
        },
        changeTimer(timeInterval, section) {
            if (section == "position") {
                this.positionTimer = timeInterval;
            } else if (section == "account") {
                this.accountTimer = timeInterval;
            }
        },
        onSearch(field) {
            setTimeout(() => {
                this.$refs[field].focus();
            }, 200);
        },
        decimalPlaceCorrection(symbol, symbolPrice) {
            if (Number.isInteger(symbolPrice)) {
                return symbolPrice;
            } else {
                if (!!this.symbolDP[symbol]) {
                    return symbolPrice.toFixed(this.symbolDP[symbol]);
                } else {
                    const numberParts = symbolPrice.toString().split(".");
                    if (numberParts.length == 1) {
                        return numberParts[0];
                    } else {
                        return (
                            numberParts[0] +
                            "." +
                            numberParts[1].substring(0, 2)
                        );
                    }
                }
            }
        },
    },
    created() {
        this.getSymbolDPAction();
        this.hardRefresh = setInterval(() => {
            this.refreshAccount();
        }, this.timeOffset * 2);
    },
    mounted() {
        if (this.lpList.length !== 0) {
            this.selectedLp = this.lpList[0].lp_name;
            this.selectedLpId = this.lpList[0].id;
        }
    },
    beforeDestroy() {
        clearInterval(this.accountTimeInterval);
        clearInterval(this.positionTimeInterval);
        clearInterval(this.hardRefresh);
    },
};
</script>

<style>
.fixCol_last.header {
    z-index: 4 !important;
}
.fixCol_first.header {
    z-index: 4 !important;
}
.fixCol_last {
    position: sticky !important;
    right: 0;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
.fixCol_first {
    position: sticky !important;
    left: 0;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
</style>
